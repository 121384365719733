<template>
  <div class="codeModule">
    <div class="moduleItem"  v-for="(item,index) in productVirtualCodePoolVos" :key="index">
        <p class="codeTitle"   v-if="item.code">券码:</p>
        <div class="codeItem">
            <div class="codeNumber">{{item.code}}</div>
            <div :id="'copy'+index"  
             class="itemCon"
             v-if="item.code"
            :data-clipboard-text="item.code"
            @click="copyCode(index)"
           >复制券码</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "virtualCode",
  data() {
    return {
      productVirtualCodePoolVos:[{ }]
    };
  },
  components: {},
  mounted() {
  },
  created(){
    this.getCodeData();
  },
  methods: {
    copyCode(index){
      var str = '#copy'+index;
      var  clipboard = new this.clipboard(str);
      clipboard.on("success", () => {
        this.$toast({
          message: "复制成功",
          position: "bottom"
        });
      });
      clipboard.on("error", () => {
        this.$toast({
          message: "抱歉，复制失败，您的手机可能不支持此功能。",
          position: "bottom"
        });
      });
    },
    getCodeData(){
      const {id} = this.$route.query;
      this.request.get('/api/scm/order/virtual/detail/code',{orderId:id}).then(res =>{
       console.log(res)
       const {productVirtualCodePoolVos} = res;
       this.productVirtualCodePoolVos = productVirtualCodePoolVos;

      })
    }
  }
};
</script>

<style lang="scss" scoped>
.codeModule {
  background: #F5F5F5;
  min-height: 100vh;
}
.moduleItem{
  width: 690px;
  height: 170px;
  background: #FFFFFF;
  border-radius: 16px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 30px;
}
.moduleTitle{
    font-size: 0.28rem;
    line-height: 0.28rem;
    padding:0.3rem 0;
    color: #ccc;
}
.codeTitle{
   width: 90px;
height: 42px;
font-size: 30px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #666666;
line-height: 42px;

margin-top: 27px;
margin-left: 20px;

}
.codeItem{
  display: flex;
  width: 100%;
  height: 0.8rem;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.codeNumber{
  width: 321px;
height: 59px;
font-size: 42px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
line-height: 59px;
margin-left: 20px;
}
.itemCon{
  color: #fff;
  line-height: 56px;
  text-align: center;
  width: 164px;
  height: 56px;
  background: linear-gradient(180deg, #FF6868 0%, #FF3737 100%);
  border-radius: 28px;
  font-size: 28px;
  margin-right: 20px;
}
</style>